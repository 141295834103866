import { graphql } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import ky from "ky";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

export default function Page({ data }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");

    ky.post("/api/graphql", {
      headers: {
        "Content-Type": "application/json",
      },
      json: {
        query: SUBSCRIBE,
        variables: {
          input: { token },
        },
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp.data.subscribe.verifiedAt);
        setLoading(false);
      });
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <div className="head">
        <a href="/">
          <img
            alt="WeStack logo"
            src="https://westack.live/logo-180.png"
            width="100"
            height="100"
          />
        </a>
        <h1>{data.site.siteMetadata.title}</h1>
      </div>

      <div style={{ textAlign: "center" }}>
        {loading ? (
          ""
        ) : (
          <Fragment>
            感谢订阅，<a href="/issues/1">点击查看最新一期。</a>
          </Fragment>
        )}
      </div>

      <Footer />
    </div>
  );
}

const SUBSCRIBE = /* GraphQL */ `
  mutation($input: SubscribeInput!) {
    subscribe(input: $input) {
      verifiedAt
    }
  }
`;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
